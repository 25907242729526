import '@angular/common/locales/global/da';
import '@angular/common/locales/global/sv';

import { LayoutModule } from '@angular/cdk/layout';
import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import { environment } from '@environments/environment';
import {
    apiHttpStateInterceptorProvider,
    apiInterceptorProvider,
    GlobalDataService,
    JsonLdModule,
    MetaModule,
    NotificationBarModule,
    PageModule,
    SeoSiteFooterModule,
    SettingsService,
    SiteFooterModule,
    SiteHeaderModule,
    SiteLayoutModule,
    SpotsConfig,
    TranslateLoaderService,
} from '@impact/ui';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomUrlSerializer } from './custom-url-serializer';

const spotsConfigFactory = (settingsService: SettingsService) => {
    const model = new SpotsConfig();
    model.settings$ = settingsService.get();
    model.googleMapsApiKey = environment.googleMapsApiKey;
    model.hasUsedCars = false;
    model.iconType = 'filled';
    model.hasUsedCars = false;
    model.isMetaLinksVisibleMobile = false;
    model.isMobileBackMultiLevel = true;
    return model;
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'bn' }),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLoaderService,
            },
        }),
        MetaModule,
        LayoutModule,
        SiteHeaderModule,
        PageModule.forRoot(),
        SiteLayoutModule,
        SiteFooterModule,
        SeoSiteFooterModule,
        SiteHeaderModule,
        // PageTransitionModule,
        AppRoutingModule,
        NotificationBarModule,
        JsonLdModule
    ],
    providers: [
        {
            provide: SpotsConfig,
            useFactory: spotsConfigFactory,
            deps: [
                SettingsService,
                GlobalDataService
            ],
        },
        {
            provide: UrlSerializer,
            useClass: CustomUrlSerializer
        },
        apiHttpStateInterceptorProvider,
        apiInterceptorProvider,
        {
            provide: LOCALE_ID,
            useValue: 'da-DK',
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'DKK'
        },
        DecimalPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
